<template>
  <v-container>
    <v-col>
      <v-card color="#385F73" dark>
        <v-card-title class="text-h5">
          Home
        </v-card-title>
      </v-card>
    </v-col>
    <v-card id="account-setting-card" class="ma-3">
      <!-- tabs -->
      <v-tabs v-model="tab" show-arrows>
        <v-tab v-for="(tab, index) in tabs" :key="index">
          <v-icon size="20" class="me-3">
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>

      <!-- tabs item -->
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(tab, index) in tabs" :key="index" class="ma-10">
          <content-section
            v-if="!loading"
            :title="content.zim.title"
            :html="content.zim.html"
            @title="e => (content.zim.title = e)"
            @html="e => (content.zim.html = e)"
          />
          <v-divider />

          <content-section
            v-if="!loading"
            :title="content.zim.title"
            :html="content.zim.html"
            @title="e => (content.zim.title = e)"
            @html="e => (content.zim.html = e)"
          />
          <v-divider />
          <content-section
            v-if="!loading"
            class="my-2"
            :title="content.iso.locations.title"
            :html="content.iso.locations.html"
            @title="e => (content.iso.locations.title = e)"
            @html="e => (content.iso.locations.html = e)"
          />
          <v-divider />
          <content-section
            v-if="!loading"
            class="my-2"
            title="NEWS"
            :html="content.news.html"
            @html="e => (content.news.html = e)"
            titleDisabled
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <div class="d-flex justify-space-between align-center my-4">
      <small>Remember to save the changes before switch the language !</small>
      <v-btn @click="save" x-large color="success" dark>
        Save changes
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Tiptap from "@/components/Tiptap";
import ContentSection from "@/components/ContentSection";

export default {
  components: {
    Tiptap,
    ContentSection
  },
  data() {
    return {
      content: {},
      loading: true,
      slug: "home-page",
      tab: 0,
      tabs: [
        { title: "English", lng: "en", icon: "mdi-flag-outline" },
        { title: "Polish", lng: "pl", icon: "mdi-flag-outline" },
        { title: "Russian", lng: "ru", icon: "mdi-flag-outline" }
      ]
    };
  },

  watch: {
    tab(e) {
      this.loadData();
    }
  },

  computed: {
    ...mapGetters({
      getLanguage: "auth/defaultLanguage"
    }),

    getTab() {
      const found = this.tabs.find((item, index) => index === this.tab);

      return found;
    },

    getIOS() {
      return this.content.iso;
    }
  },

  methods: {
    ...mapActions({
      getContentBySlug: "content/getContentBySlug",
      update: "content/update"
    }),

    save() {
      const { lng } = this.getTab;

      try {
        this.update({
          lng,
          slug: "home-page",
          value: JSON.stringify(this.content)
        });

        this.$toast.success("Content successful updated");
      } catch (error) {
        this.$toast.error(error);
      }
    },

    async loadData() {
      const { lng } = this.getTab;

      if (lng) {
        const data = await this.getContentBySlug({
          params: { lng, slug: this.slug }
        });

        this.content = JSON.parse(JSON.stringify(data));
        this.loading = false;
      }
    }
  },

  mounted() {
    this.tab = this.getLanguage.id;
    this.loadData();
  }
};
</script>
